@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


@font-face {
  font-family: 'Inky Thin Pixels(MONKEYBOY)';
  src: url('../fonts/Inky-Thin-Pixels_0.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}

/*loader */
.loader {
  border: 4px solid  #429dff;
  border-top: 4px solid #dddddd82;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
  margin:  auto;



}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* header */

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 2;
  position: relative;

}
.logo{
  width: 20%;

}
.logo__text{
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: 36px;
  letter-spacing: 3px;
  color: #2c2c2e;
  padding-left: 2px;
text-transform: uppercase;
}
.thanks__logo{
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: 36px;
  letter-spacing: 3px;
  color: #2c2c2e;
  text-transform: uppercase;
}
.logo img{
  width: 100%;
}
.header__top{
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items:center;
}

.header__lang {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;

  width: 30%;
  height: 40px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  color: #000000;
  display: flex;
  align-items: center;
  justify-content: end;
  font-weight: 800;


}
.back{
   background-color: #dddddd82; 
   justify-content: center;
   border-radius: 10px; 
    border: 1px solid #2c2c2e; 
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); 
}


.header__marquee-container {
  width: 100%;
  text-transform: uppercase;

  overflow: hidden;
  height: 40px;
  border: 1px solid #2c2c2e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  background-color: #333333b5;
  border-radius: 9px;
  color: #ffffff;
  padding-top: 10px;

}

.header__lang img {
  width: 30px;
}

.header__lang-back {
  width: 50px !important;
}

.header__marquee-anim {
  width: 100%;


  display: flex;

  animation: scroll 20s linear infinite;

}

.header__marquee-anim span {

  font-family: 'Inky Thin Pixels(MONKEYBOY)', sans-serif;
  line-height: 16px;
  font-size: 16px;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-180%);
  }
}



/* items */




.items {
  display: flex;
  flex-wrap: wrap;

  gap: 10px;
  z-index: 2;
  position: relative;
}

.item {
  word-break: break-word;
  width: 24%;
  padding: 8px ;
  border: 1px solid #2c2c2e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  background-color: #c1cce4;
  border-radius: 20px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  transition: .3s all linear;
  cursor: pointer;


}



.item:hover {

  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

.item__img {
  width: 50px;
  height: 50px;

}

.item__img img {
  width: 100%;
  height: 100%;
  border-radius: 100%
}

.item__name {
  font-size: 12px;
  font-weight: 800;
  text-align: center;

}

.item__descr {
  text-align: center;
  font-size: 8px;
  font-weight: 800;
}

/* modal */




.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .2s all;
}

.modal-content {
  background-color: #d6d6d6;
  border-radius: 20px;
  border: 1px solid #2c2c2e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding: 10px;
  box-sizing: border-box;
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.modal__top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;

}

.modal__top-img {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 20px;
  border: 1px solid #2c2c2e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

}

.modal__top-img img {
  width: 90px;
  height: 90px;
  border-radius: 100%;

}

.modal__top-right {
  padding: 20px 0 10px;

  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.modal__top-right__title {
  font-size: 30px;
  font-weight: 900;
  color: #000000;

}

.modal__top-right__descr {
  color: #808080;

}

.modal__middle {
  margin-top: 10px;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  font-weight: 900;

}

.modal__bottom {
  display: flex;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 15px;
  padding: 0 10px;


  gap: 20px;

}

.modal__middle-btn {
  margin-top: 10px;
  cursor: pointer;
  background: none;
  border: none;
  color: #adb6ff;
  font-size: 16px;
  font-weight: 900;
}

.modal__btn {
  width: 50%;
  text-align: center;
  text-decoration: none;
  padding: 10px 10px;
  background-color: #d6d6d6;
  font-weight: 900;
  font-size: 18px;
  color: rgb(0, 0, 0);
  border-radius: 10px;
  border: 1px solid #2c2c2e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;
  cursor: pointer;

}

.modal__btn:hover {
  background-color: #c1cce4;
  color: #fff;
}

/* form */



.form__block {
  width: 30%;

  border-radius: 8px;
  margin: 0 auto;
  padding: 0 20px 20px;

}

.form__error {
  background: #f9d7da;
  border: 1px solid #f1aeb5;
  padding: 16px;
  border-radius: 8px;

}

.form__error-text {
  color: #58151c;
  font-family: "Roboto", sans-serif;

}

.form__error ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 5px;

}

.form__error li {
  position: relative;
  padding-left: 20px;

}

.form__error li::before {
  content: '';
  width: 6px;
  height: 6px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  background: #58151c;
}

.form__block form {
  display: flex;
  flex-direction: column;
  gap: 20px;


}

.form__title {
  text-align: center;
  font-size: 28px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-weight: 500;

}

.form__close {
  text-align: end;
  margin-bottom: 10px;

}

.form__close img {
  width: 30px;
  margin-right: -24px;
}


.form__grup {
  position: relative;

}

.form__btn {
  font-family: "Roboto", sans-serif;
  width: 100%;
  background-color: #429dff;
  color: #fff;
  font-size: 23px;
  padding: 20px 0;


  font-weight: 400;
  cursor: pointer;
  transition: .2s all linear;
  color: white;
  border-radius: 10px;
  border: 1px solid #2c2c2e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.form__btn:hover {
  background-color: #429dff;
}

.form__grup-input {
  padding: 20px 20px 0;
  width: 100%;
  height: 60px;
  border: 1px solid #2c2c2e;
  border-radius: 8px;
  font-size: 16px;
  background-color: #c1cce4;
}

.form__grup-label,
.form__grup-label__textarea {
  font-family: "Roboto", sans-serif;
  position: absolute;
  top: 50%;
  left: 20px;
  font-weight: 400;
  transform: translateY(-50%);
  font-size: 18px;
  color: #000000;
  transition: all 0.3s ease;
  pointer-events: none;
  background: #ffffff00;
  padding: 0 5px;
}

.form__grup-input:focus~label {

  color: #00000069;
  font-size: 15px;
  top: 20%;



}

.form__grup-input:not(:placeholder-shown)~label {

  font-size: 15px;
  color: #00000069;
  top: 20%;
}

.form__grup-input:focus {
  border: 2px solid #2c2c2e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  outline: none;
}


.form__grup-textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;

  max-height: 200px;
  padding: 30px 20px 0;
  background-color: #c1cce4;

  border: 1px solid #2c2c2e;
  border-radius: 8px;
  font-size: 16px;
}

.form__grup-label__textarea {

  top: 30px;
  left: 20px;


}

.form__grup-textarea:focus~label {

  color: #00000069;
  font-size: 15px;
  top: 12px;



}

.form__grup-textarea:not(:placeholder-shown)~label {

  font-size: 15px;
  color: #00000069;
  top: 12px;
}

.form__grup-textarea:focus {
  border: 2px solid #2c2c2e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  outline: none;
  background-color: #c1cce4;
}

.form__logo-label {
  display: inline-block;
  padding: 15px 0px;
  background-color: #f8f9fa;
  border: 1px solid #2c2c2e;
  border-radius: 8px 0 0 8px;
  font-size: 14px;
  width: 30%;
  cursor: pointer;
  text-align: center;
  font-family: "Roboto", sans-serif;
  transition: background-color 0.3s ease;
}

.form__logo-label:hover {
  background-color: #0056b3;
}

.form__logo-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.form__grup-nameImg {
  width: 70%;
  font-family: "Roboto", sans-serif;
  background: #c1cce4;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-left: 20px;
  border: 1px solid #2c2c2e;
  border-left: 0;

}

.form__grup-label__wrapper {
  display: flex;
}

.form__text {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
}

.logo__edit {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo__slider {
  margin-top: 18px;
  width: 300px;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 3px;
  background: #7c7c7d;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #429dff;
  border-radius: 50%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #429dff;
  border-radius: 50%;
  cursor: pointer;
}

.slider::-ms-thumb {
  width: 15px;
  height: 15px;
  background: #dddddd;
  border-radius: 50%;
  cursor: pointer;
}


/* THANKS */

.thanks {

  background-color: #c1cce4;
  width: 30%;
  border-radius: 10px;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border: 1px solid #2c2c2e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);


}

.thanks__text {
  color: #000000;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;

}

.thanks__img {

  width: 70%;


}

/* ERROR */
.error {
  width: 100%;

  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center
}

.error__wrapper {
  text-align: center;


}

.error__text-small {
  font-size: 20px;



}

.error__text-main {
  font-family: "Roboto", sans-serif;
  font-size: 150px;
  font-weight: 700;

}

.error__text-descr {
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 30px;

}

.error__text-descr span {
  font-weight: 900;
}

@media(max-width: 789px) {
  .item {
    width: 32%;
  }

 

  .mobile {

    min-height: 100vh;
    background-color: rgb(248 249 250);
    background-color: #3d3d40;
  }
}

@media (max-width: 540px) {
  .item {
    width: 31%;
  }

  .header__lang {
    padding-top: 3px;
    padding-right: 12px;
    font-size: 0px;
  }
  .logo{
    width: 70%;
  
  }

  .item:hover {
    transform: scale(1);
    box-shadow: inherit;
  }

  .modal__top-right {
    padding: 20px 0 0px;
    width: 70%;

  }

  .modal__top-right__title {
    font-size: 27px;
  }

  .modal__top-img {
    width: 30%;
  }

  .form__block {
    width: 100%;
  }

  .form__title {

    font-size: 20px;
  }

  .form__grup-label,
  .form__grup-label__textarea {

    font-size: 14px;
  }

  .form__grup-label__textarea {
    top: 30px;
  }

  .form__grup-textarea:not(:placeholder-shown)~label {
    font-size: 13px;
  }

  .form__grup-textarea:focus~label {

    font-size: 13px;
  }

  .form__logo-label {

    width: 40%;
  }

  .form__grup-nameImg {
    width: 60%;
    padding: 0 10px;
  }

  .form__btn {

    font-size: 20px;
  }

  .thanks {


    width: 100%;
  }
  .back{
    padding-top: 0px;
        padding-right: 0px;
        font-size: 15px;
  }

  .header__marquee-anim {





    animation: scroll 30s linear infinite;

  }

  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(-800%);
    }
  }
}