@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');

* {
  font-family: "Comfortaa", sans-serif;
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  text-decoration: none !important;
}





.wrapper{
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-image: url(./img/26987163_v904-nunny-011-f.jpg);
  min-height: 100vh;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
 
}

